<template>
    <tr class="user-settings-checkbox" :class="checkbox.rowClass">
        <td class="user-settings-modal-td-desc">
            <span data-bind="text: label">{{checkbox.label}}</span>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        <a v-if="checkbox.displayTooltip === true"
                            :id="checkbox.tooltipId"
                            :data-content="checkbox.tooltipMessage"
                            @click="checkbox.showPopover"
                            data-placement="top">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                    </div>
                    <div v-if="checkbox.displayUpgradeTooltip === true"
                        class="increment-div info-tooltip-div info-upgrade-div">
                        <a :id="checkbox.upgradeTooltipMessageId"
                            :data-content="checkbox.upgradeTooltipMessage"
                            @click="checkbox.showUpgradePopover"
                            data-placement="top">
                            <i class="fa fa-chevron-circle-up fa-lg"></i>
                        </a>
                    </div>
                </div>
                <div class="menu-checkbox-div" @click="checkbox.toggleCheckbox" :class="{ 'menu-checkbox-div-readonly': checkbox.isDisabled }">
                    <i :class="{ 'fa-check-square': checkbox.obv.value === true, 'fa-square': checkbox.obv.value === false }"
                        class="far fa-lg" >
                    </i>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'InputTypeCheckbox',
    props: {
        inputFromParent: Object
    },
    setup (props) {
        const checkbox = toRef(props, "inputFromParent")

        return {
            checkbox
        }
    }
}
</script>

<style lang="scss" scoped>

</style>